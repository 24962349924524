@font-face {font-family: "Almarai";font-style: normal;font-weight: 300;font-display: swap;src: url(https://fonts.gstatic.com/s/almarai/v12/tssoApxBaigK_hnnS_antnqWow.woff2)format("woff2");}
@font-face {font-family: "Almarai";font-style: normal;font-weight: 400;font-display: swap;src: url(https://fonts.gstatic.com/s/almarai/v12/tsstApxBaigK_hnnQ1iFow.woff2)format("woff2");}
@font-face {font-family: "Almarai";font-style: normal;font-weight: 700;font-display: swap;src: url(https://fonts.gstatic.com/s/almarai/v12/tssoApxBaigK_hnnS-agtnqWow.woff2)format("woff2");}
@font-face {font-family: "Almarai";font-style: normal;font-weight: 800;font-display: swap;src: url(https://fonts.gstatic.com/s/almarai/v12/tssoApxBaigK_hnnS_qjtnqWow.woff2)format("woff2");}
:root {
    --certificate-color: #1074bc;
  }
* {
    outline: none;
    padding: 0;
    box-sizing: border-box;

}
*::after ,*::before{
    margin: 0;
    padding: 0;
    box-sizing: border-box;

}
::selection {
    color: #fff;
    background: #000;
}
html {
  font-family: "Almarai", sans-serif;
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
body {
  margin: 0;
  font-family: "Almarai", sans-serif;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5;
  color: #7e7e7e;
  text-align: left;
  background-color: #f7f7f7;
}
body {
    overflow-x: hidden;
    height: 100%;
    position: relative;
    max-width: 100%;
    font-size: 0.875rem;
  }
@media only screen and (max-width: 991px) {
    body {
      font-size: 0.875rem;
    }
  }
h3 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
  font-size: 1.5rem;
  color: #3d4465;
  color: #000;
  font-weight: 600;
}
img {
  vertical-align: middle;
  border-style: none;
}
svg {
  overflow: hidden;
  vertical-align: middle;
}
input {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  overflow: visible;
}
.col-6,
.col-md-6,
.col-lg-5,
.col-lg-7 {
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;
}
.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}
@media (min-width: 768px) {
  .col-md-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }
}
@media (min-width: 992px) {
  .col-lg-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%;
  }
  .col-lg-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%;
  }
}
.d-flex {
  display: flex !important;
}
.flex-column {
  flex-direction: column !important;
}
.flex-column-reverse {
  flex-direction: column-reverse !important;
}
@media (min-width: 768px) {
  .flex-md-row {
    flex-direction: row !important;
  }
}
@media (min-width: 992px) {
  .flex-lg-row {
    flex-direction: row !important;
  }
}
.p-0 {
  padding: 0 !important;
}
@media print {
  *,
  *::before,
  *::after {
    text-shadow: none !important;
    box-shadow: none !important;
  }
  img {
    page-break-inside: avoid;
  }
  h3 {
    orphans: 3;
    widows: 3;
  }
  h3 {
    page-break-after: avoid;
  }
  body {
    min-width: 992px !important;
  }
}
.certificate-wrapper {
  height: 100vh;
  padding: 50px 0;
}
.certificate-container {
  border-right: 15px solid #f26c5f;
  border-left: 15px solid #f26c5f;
}
.certificate-content {
  padding: 0 5vw;
  display: flex;
}
.certificate-header-block {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 20px 0;
  margin-bottom: 20px;
}
.certificate-header-block span,
.certificate-content-block span {
  color: black;
  font-size: 18px;
  line-height: 23px;
}
.certificate-name-holder {
  margin: 30px 0;
  height: 42px;
}
.certificate-name-holder h3 {
  color: var(--certificate-color);
  font-weight: 800;
  font-size: 35px;
}
.certificate-course-holder h3 {
  color: var(--certificate-color);
  font-weight: 800;
  font-size: 25px;
  font-style: italic;
}
.certificate-course-holder {
  margin: 10px 0 20px;
  height: 38px;
}
.certificate-content-block {
  border-top: 1px solid black;
  padding: 20px 0;
}
.certificate-content-block .span-bold {
  font-weight: 900;
  font-size: 20px;
  margin: 10px 0 20px;
}
.certificate-info-holder {
  border-top: 1px solid black;
  border-bottom: 1px solid black;
  padding: 10px 0;
  margin-bottom: 20px;
  display: flex;
  color: black;
  font-weight: 900;
  font-size: 18px;
  font-style: italic;
}
.certificate-info-holder .certificate-date,
.certificate-info-holder .certificate-hours {
  color: var(--certificate-color);
}
.certificate-hours {
  width: 35px;
  display: inline-flex;
  justify-content: center;
}
.certificate-footer-holder {
  margin-top: 40px;
  display: flex;
  flex-direction: column;
}
.certificate-footer-holder span {
  color: black;
  font-size: 12px;
  margin-top: 15px;
}
.certificate-left {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}
.certificate-logo-holder {
  width: 50%;
  margin-bottom: 20px;
}
.certificate-author-holder {
  color: black;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
.certificate-badge-holder {
  margin-bottom: 20px;
}
.certificate-author-holder.secondary-author {
  color: var(--certificate-color);
}
.certificate-author-holder .certificate-author {
  font-weight: 900;
}
.certificate-meta-holder {
  display: flex;
  color: black;
  font-size: 12px;
  margin-bottom: 40px;
  width: 50%;
  justify-content: center;
}
.certificate-meta-holder .certificate-meta-info {
  color: var(--certificate-color);
}
@media (max-width: 576px) {
  .certificate-logo-holder {
    width: 75%;
  }
  .certificate-name-holder h3 {
    font-size: 27px;
  }
  .certificate-course-holder h3 {
    font-size: 21px;
  }
  .certificate-meta-holder {
    width: 100%;
  }
}